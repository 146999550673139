@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-poppins;
  }
  h1 {
    @apply text-[64px]  md:text-[100px] font-semibold  lg:leading-[163px] text-white;
  }
  h2 {
    @apply text-3xl md:text-5xl font-semibold;
  }
  h3 {
    @apply text-xl font-semibold md:text-2xl;
  }
  h4 {
    @apply text-xl font-semibold;
  }
  h5 {
    @apply text-lg font-medium;
  }
  li {
    @apply font-semibold lg:text-2xl;
  }

  input {
    @apply bg-Inputs py-4 rounded-xl pl-5 text-bluecoming;
  }

  label {
    @apply text-corps text-sm font-semibold;
  }

  select {
    @apply bg-Inputs py-4 rounded-xl px-5 text-bluecoming;
  }

  textarea {
    @apply bg-Inputs rounded-xl min-h-[341px] lg:min-h-[228px] pl-5 pt-5 text-bluecoming;
  }

  .p-confi {
    @apply text-corps leading-8;
  }
}
